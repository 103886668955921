import React from "react";
import ReactDOM from "react-dom";
import { each, size, keys, intersection } from "underscore";
import { DropdownButton, MenuItem } from "react-bootstrap";
import ConsoleStore from "ConsoleStore";
import ONeilViewStore from "ONeilViewStore";
import OwnershipViewStore from "OwnershipViewStore";
import ListManagerTabType from "ListManagerTabType";
import GridActions from "GridActions";
import { GridConstants } from "GridConstants";
import ListActions from "ListActions";
import BrowserUtil from "Utils/BrowserUtil";
import StringUtil from "Utils/StringUtil";
import { getParentNodeDescription } from "Utils/OwnerShipUtil";

import ResizableWindow from "RayCustomControls/ResizableWindow/ResizableWindow.jsx";
import ListStore from "ListStore";
import KeyCodes from "KeyCodes";
import ScrollBar from "ScrollBar";
import ListExplorerStore from "ListExplorerStore";
import LocalizationStore from "LocalizationStore"
import UserInfoUtil from 'UserInfoUtil';
import {addMetric} from '../../Actions/customMetricsAction';
import { ListExplorerConstants } from "ListExplorerConstants";
import { dispatch } from "../../Redux/dispatch";

let interval, metricList, listState, metricIndex = undefined;
let columnsinList, metricDetail = [];
let childMetricsBlockHeight = "100%";
let firstIndex = 0;
let isDetailClicked = true;
let resizerHeight = 0;
let numOfLookbacks = 0;
let numOfIntervals = 0;
let lookbackAttributes = {}; let intervalAttributes = {};
let isIntervalDropUp = undefined;
let isLookbackDropUp = true;
let scrollTop = 0;
let propsVal =null;

export default class MetricLibraryDetail extends React.Component {
    constructor(props) {
        super(props);
        this.listStoreStateChange = this.listStoreStateChange.bind(this);
        this.isIntervalAvailable = this.isIntervalAvailable.bind(this);
        this.handleIntervalChange = this.handleIntervalChange.bind(this);
        this.handleLookbackChange = this.handleLookbackChange.bind(this);
        this.handleKeyChange = this.handleKeyChange.bind(this);
        this.getColumnsInList = this.getColumnsInList.bind(this);
        this.getAddColumnButtonClassName = this.getAddColumnButtonClassName.bind(this);
        this.isColumnExist = this.isColumnExist.bind(this);
        this.getIntervalTitle = this.getIntervalTitle.bind(this);
        this.getScrollValueForSearch = this.getScrollValueForSearch.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.updateScroll = this.updateScroll.bind(this);
        this.onResize = this.onResize.bind(this);
        this.updateDropDownPositions = this.updateDropDownPositions.bind(this);
        this.ellipsizeDefinition = this.ellipsizeDefinition.bind(this);
        this.updateDropdownScroll = this.updateDropdownScroll.bind(this);
        this.toggleLookbackDropdownButton = this.toggleLookbackDropdownButton.bind(this);
        this.toggleLookbackMenuItem = this.toggleLookbackMenuItem.bind(this);
        this.handleAddMetric = this.handleAddMetric.bind(this);
        this.state = {
            inSearchMode: undefined,
            detailData: [],
            selectedDetail: undefined,
            selectedMetric: undefined,
            selectedItemData: undefined,
            itemDataForSelectedPeriodicity: undefined,
            selectedPeriodicity: undefined,
            selectedLookBack: undefined,
            lookbacks: [],
            isResized: false,
            openLookback: false
        }
        this.metricLibrarySettings = ListStore.getMetricLibrarySettings();
    }

    handleAddMetric() {
        let listStoreData = ListStore.getCustomMetricState();
        if (this.state.selectedItemData && !listStoreData) {
            this.props.removeOverlay();
            if (this.props.isColumnFilter == true) {
                ListActions.updateColumnNameinFilter(this.state.selectedItemData);
                ListActions.toggleMetricLibrary();
                this.props.clearState();
            }
            else {
                console.log('listStore state while add ',listStoreData);
                ONeilViewStore.isListLoaded = false;
                columnsinList.length < 100 ? GridActions.addColumnToList(GridConstants.ActionTypes.ADD_COLUMN_TO_LIST_FROM_METRIC, listState.actualListId, this.state.selectedItemData.MsItemID, null, null, false) : ListActions.toggleAlertDialog100Columns(true);
            }
            //Log Usage
            this.trackUsage();
        }else{
            let selectedItemData = this.state.selectedItemData;
            let allCategory = this.props.dataSource;
            let metricsOpen = true;
            let selectedCategory = allCategory.find(function(element) {
                return element.CategoryId == selectedItemData.CategoryId;
            });
           dispatch(addMetric(listState.actualListId,selectedItemData,selectedCategory,metricsOpen, true));
        }
    }

    componentWillUnmount() {
        isDetailClicked = true;
        scrollTop = 0;
        this.setState({ selectedMetric: undefined, inSearchMode: undefined, isResized: false });
        ListStore.removeChangeListener(this.listStoreStateChange);
    }


    UNSAFE_componentWillMount() {
        if (this.props.inSearchMode == true) { isDetailClicked = false }
    }

    componentDidMount() {
        ListStore.addChangeListener(this.listStoreStateChange);
        // this.upDateHeight();
        this.updateScroll();
        setTimeout(() => {
            this.updateDropDownPositions()
        }, 0)

        //this.metricsList.focus();
        // if (isDetailClicked) this.ellipsizeDefinition();
    }

    // upDateHeight = () => {
    //     let totalHeight = document.getElementsByClassName("inner-panel-body")[1] ? document.getElementsByClassName("inner-panel-body")[1].clientHeight - this.props.searchPanelHeight : this.props.searchPanelHeight;
    //     this.props.updateDetailListHeight(totalHeight);
    // }


    componentDidUpdate() {
        this.updateScroll();
        //this.updateDropDownPositions();
        if (isDetailClicked) this.updateDropdownScroll();
        // if (isDetailClicked) this.ellipsizeDefinition();
    }

    updateScroll() {
        let div = document.getElementById("merticLibraryDetailVerticalScroll")
        window.setTimeout(() => { div.scrollTop = scrollTop; }, 0);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let div = document.getElementById("merticLibraryDetailVerticalScroll")
        window.setTimeout(() => { div.scrollTop = nextProps.scrollTop; }, 0);
        if (nextProps.dataSource && !StringUtil.compare(nextProps.dataSource, this.props.dataSource)) {
            let metricDetails = nextProps.dataSource;
            const currentDetailIndex = this.state.selectedItemData ? metricDetails.findIndex((i) => i.CategoryId === this.state.selectedItemData.CategoryId) : 0;
            let metric = metricDetails[currentDetailIndex];
            if (!metric) return;
            let firstChild = this.arrangeIntervals(Object.keys(metric.childNode), metric.childNode);
            let sortedItemData = this.sortItemdataForPeriodicity(metric.childNode[firstChild]);
            if (this.state.inSearchMode == false) {
                this.setState({
                    selectedDetail: metric,
                    selectedMetric: metric.childNode,
                    selectedItemData: sortedItemData[firstIndex]
                });
            }
        }
    }

    listStoreStateChange() {
        let currentAction = ListStore.getCurrentAction();
        let metricDetails = this.props.dataSource;
        if (currentAction == ListExplorerConstants.ActionTypes.CUSTOM_METRIC_CHANGED) {
            let nextAvailabDetail = ListStore.getNextAvailabDetail();
            if (nextAvailabDetail && nextAvailabDetail.showMetricDetails !== undefined && nextAvailabDetail.showMetricDetails === false) {
                this.props.toggleShowMetricDetails();
            } else if (nextAvailabDetail && nextAvailabDetail.CategoryName !== this.state.selectedDetail.CategoryName) {
                const nextAvailabDetailIndex = metricDetails.findIndex((i) => i.CategoryName === nextAvailabDetail.CategoryName)
                let metric = metricDetails[nextAvailabDetailIndex];
                let firstChild = this.arrangeIntervals(Object.keys(metric.childNode), metric.childNode);
                let sortedItemData = this.sortItemdataForPeriodicity(metric.childNode[firstChild]);
                if (this.state.inSearchMode == false) {
                    this.setState({
                        selectedDetail: metric,
                        selectedMetric: metric.childNode,
                        selectedItemData: sortedItemData[firstIndex]
                    });
                }
            }
        }
    }

    onResize(height) {
        this.props.updateDetailListHeight(height);
        this.setState({ isResized: true })
    }

    getMetricsList(metricDetails) {
        metricDetail = [];
        let list = {};
        let self = this;
        if (this.state.inSearchMode == undefined) this.state = { ...this.state, inSearchMode: this.props.inSearchMode };

        if (metricDetails.length > 0) {
            if (this.state.inSearchMode == false) {
                let childNode = metricDetails[firstIndex].childNode;
                if (this.props.isColumnFilter == false) {
                    if (this.state.selectedDetail == undefined) this.state = { ...this.state, selectedDetail: metricDetails[firstIndex] };
                    if (this.state.selectedMetric == undefined) this.state = { ...this.state, selectedMetric: childNode };
                }

                if (this.state.selectedItemData == undefined) {
                    let firstItem = Object.keys(childNode)[firstIndex];
                    this.state = { ...this.state, selectedItemData: childNode[firstItem][firstIndex] };
                }
            }
            each(metricDetails, (detail) => {
                let data = { CategoryName: "", ItemData: {} };
                data.CategoryName = detail.CategoryName;
                data.CategoryId = detail.CategoryId;
                data.ItemData = detail.childNode;
                metricDetail.push(data);
            })

            let selectedDetail = this.state.selectedDetail;
            this.state.selectedItemData && ListStore.setCurrentSelectedDetailId(this.state.selectedItemData.MsItemID);
            if (metricIndex == undefined) { metricIndex = 0; }

            list = metricDetail.map((metric, index) => {
                let activeMetric = ""; let name = "";
                let handleMetricChange = self.handleDetailMetricChange.bind(self, metric, index);
                let handledoubClickChange = self.handleDetailDoubleClickChange.bind(self,metric);
                if (selectedDetail) {
                    activeMetric = (selectedDetail.CategoryId == metric.CategoryId) ? "icn-right-icon" : '';
                    name = (selectedDetail.CategoryId == metric.CategoryId) ? "active" : '';
                }
                return (<li className={name + " list-metric-item"} key={index + 1}><a className="matric-head details" onDoubleClick={handledoubClickChange} onClick={handleMetricChange} key={index + 1}><div className={activeMetric + " blank-sp"}></div>{LocalizationStore.getTranslatedData("MetricLibraryCategory_" + StringUtil.stringReplace(metric.CategoryName), metric.CategoryName)}</a></li>);
            });
        } else {
            list = <div className="text-center xx-small-bold">{LocalizationStore.getTranslatedData("LM_TP_NoMatcheMsg_1", "There are no matches for your search.") + LocalizationStore.getTranslatedData("LM_TP_NoMatcheMsg_2", "Please clear the search field and try again.")}</div>
        }
        return list;
    }

    handleDetailDoubleClickChange(metric) {
        if (this.props.isColumnFilter == true) {
            let firstChild = this.arrangeIntervals(Object.keys(metric.ItemData), metric.ItemData);
                let sortedItemData = this.sortItemdataForPeriodicity(metric.ItemData[firstChild]);
                this.setState({
                    selectedDetail: metric, selectedMetric: metric?.ItemData, itemDataForSelectedPeriodicity: sortedItemData,
                    selectedItemData: sortedItemData[firstIndex], selectedPeriodicity: firstChild, selectedLookBack: sortedItemData[firstIndex].timeback,
                    inSearchMode: false,
                    selectedLookBack: undefined
                },()=>{
                    this.props.removeOverlay();
                    ListActions.updateColumnNameinFilter(this.state.selectedItemData);
                    ListActions.toggleMetricLibrary();
                    this.props.clearState();
                    this.trackUsage();
                    });
        }
        else{
                if (this.isColumnExist() == false && this.state.inSearchMode == false) {
                GridActions.addColumnToList(GridConstants.ActionTypes.ADD_COLUMN_TO_LIST_FROM_METRIC, listState.actualListId, this.state.selectedItemData.MsItemID, null, null, false);
                }
        }
        
        
    }

    removeCurrentItem(selectedMetrics) {
        each(selectedMetrics, (selection, key) => {
            if (selection == "Current") selectedMetrics.splice(key, 1);
        })
        each(selectedMetrics, (selection, key) => {
            if (selection == "Quarterly") {
                let arrangedIntervals = [selection];
                selectedMetrics.splice(key, 1);
                each(selectedMetrics, (interval) => arrangedIntervals.push(interval))
                selectedMetrics = arrangedIntervals;
            }
        })
        return selectedMetrics;
    }

    ellipsizeDefinition() {
        let padding = 10;
        let descriptionDiv = document.getElementsByClassName("dynamic-description-title-details")[0];
        let topDiv = document.getElementsByClassName("matric-block-inner-block")[0];
        let topPanelDiv = document.getElementsByClassName("pull-left w100p")[0];
        let searchPanelDiv = document.getElementsByClassName("search-field medium-bold")[0];
        let innerPanelDiv = document.getElementsByClassName("inner-panel-body  matric-block-wrap  dialog-content")[0];

        let topPanelHeight = this.props.inSearchMode ? searchPanelDiv.clientHeight : topPanelDiv.clientHeight;
        let descriptionHeight = innerPanelDiv.clientHeight - topPanelHeight - topDiv.clientHeight;
        //let isEllipsisRequired = descriptionDiv.clientHeight > descriptionHeight ? true : false;

        let descriptionParentDiv = document.createElement('div');
        descriptionParentDiv.className = "description-parent-div";
        descriptionParentDiv.style.height = descriptionHeight + 'px';
        descriptionParentDiv.style.width = this.props.width - (padding * 2) + 'px';
        descriptionParentDiv.style.visibility = 'hidden';

        let descriptionActualChildDiv = document.createElement('div');
        descriptionActualChildDiv.className = "description-child-div";

        descriptionParentDiv.appendChild(descriptionActualChildDiv)
        document.body.appendChild(descriptionParentDiv);
        if (this.state.selectedItemData && this.state.selectedItemData.Definition != null) this.ellipsisFill(descriptionDiv,
            LocalizationStore.getTranslatedData("DataItemExt_Definition_" + this.state.selectedItemData.MsItemID, this.state.selectedItemData.Definition)
            , descriptionParentDiv, descriptionActualChildDiv);
    }

    ellipsisFill(e, html, parentDiv, childDiv) {
        let joinChar = UserInfoUtil.getCurrentLangCode() === 'en-us' ? ' ' : '';
        let x = function (a, i) {
            let splice = a.slice(0, i);
            return joinChar ? splice.join(joinChar) : splice;
        };
        let d = '...',
            h = parentDiv.offsetHeight - 10,
            w = joinChar ? html.split(joinChar) : html,
            i = 0,
            l = w.length;

        e.innerHTML = '';
        while (h + 1 >= childDiv.scrollHeight && i <= l) {
            childDiv.innerHTML = x(w, ++i) + d;
        }
        if (i > l) { childDiv.innerHTML = x(w, i); }
        else { childDiv.innerHTML = x(w, --i) + d; }

        e.innerHTML = childDiv.innerHTML;
        if (e.innerHTML == '...') e.innerHTML = '';
        document.body.removeChild(parentDiv);
    }

    onScroll() {
        let scrollValue = document.getElementById("merticLibraryDetailVerticalScroll").scrollTop;
        scrollTop = scrollValue;
    }

    getRequiredLookBacks() {
        let requiredlookbacks = [];
        let itemDataForPeriodicity = this.state.itemDataForSelectedPeriodicity;
        each(itemDataForPeriodicity, (itemdata) => {
            requiredlookbacks.push(itemdata.timeback);
        })
        let lookbackTitles = [];
        for (let i = 0; i < requiredlookbacks.length; i++) {
            if (requiredlookbacks[i] == 0) { lookbackTitles.push(LocalizationStore.getTranslatedData("LM_TP_Lookback_01", "Most Recent")); } else {
                let agoLabel = LocalizationStore.getTranslatedData("LM_TP_Lookback_07",
                    "{0} {1} ago",
                    requiredlookbacks[i], this.getIntervalTitle(requiredlookbacks[i]));
                lookbackTitles.push(agoLabel);
            }
        }
        return lookbackTitles;
    }

    sortItemdataForPeriodicity(itemDataForPeriodicity) {
        let sortedData = [];
        each(itemDataForPeriodicity, (itemdata) => {
            if (itemdata.timeback == 0) sortedData.push(itemdata);
        })
        each(itemDataForPeriodicity, (itemdata) => {
            if (itemdata.timeback != 0) sortedData.push(itemdata);
        })
        return sortedData;
    }

    getIntervalTitle(lookback) {
        let title = "";
        //bug fixes - panweb-1216 - (Note: no hardcoded value, data came from api)
        let lookbackText = this.props.periodicityData[this.state.selectedPeriodicity].lookbackText;
        if (lookback == 1) { title = `${lookbackText}`; } else { title = `${lookbackText}s`; }

        //--->Please TODO- Localization

        switch (this.state.selectedPeriodicity) {
            case "Annual": if (lookback == 1) { title = LocalizationStore.getTranslatedData("LM_TP_Lookback_06", "year"); } else { title = LocalizationStore.getTranslatedData("LM_TP_Lookback_06", "years"); } break;
            case "Quarterly": if (lookback == 1) { title = LocalizationStore.getTranslatedData("LM_TP_Lookback_05", "quarter"); } else { title = LocalizationStore.getTranslatedData("LM_TP_Lookback_05", "quarters"); } break;
            case "Monthly": if (lookback == 1) { title = LocalizationStore.getTranslatedData("LM_TP_Lookback_04", "month"); } else { title = LocalizationStore.getTranslatedData("LM_TP_Lookback_04", "months"); } break;
            case "Weekly": if (lookback == 1) { title = LocalizationStore.getTranslatedData("LM_TP_Lookback_03", "week"); } else { title = LocalizationStore.getTranslatedData("LM_TP_Lookback_03", "weeks"); } break;
            case "Daily": if (lookback == 1) { title = LocalizationStore.getTranslatedData("LM_TP_Lookback_02", "day"); } else { title = LocalizationStore.getTranslatedData("LM_TP_Lookback_02", "days"); } break;
            case "Trailing 4 Quarters": if (lookback == 1) { title = LocalizationStore.getTranslatedData("LM_TP_Lookback_05", "quarter"); } else { title = LocalizationStore.getTranslatedData("LM_TP_Lookback_05", "quarters"); } break;
            case "Semiannual": if (lookback == 1) { title = LocalizationStore.getTranslatedData("LM_TP_Lookback_10", "period"); } else { title = LocalizationStore.getTranslatedData("LM_TP_Lookback_10", "periods"); } break;
            case "Trailing 2 Semiannuals": if (lookback == 1) { title = LocalizationStore.getTranslatedData("LM_TP_Lookback_10", "period"); } else { title = LocalizationStore.getTranslatedData("LM_TP_Lookback_10", "periods"); } break;
            default: break;
        }
        return title;
    }

    handleDetailMetricChange(metric, index, e) {
        if (!isDetailClicked) isDetailClicked = true;
        let newscrollTop = this.state.inSearchMode == true ? this.getScrollValueForSearch(e, index) : scrollTop;
        metricIndex = index;
        let firstChild = this.arrangeIntervals(Object.keys(metric.ItemData), metric.ItemData);        
        let sortedItemData = this.sortItemdataForPeriodicity(metric.ItemData[firstChild]);
        this.setState({
            selectedDetail: metric, selectedMetric: metric.ItemData, itemDataForSelectedPeriodicity: sortedItemData,
            selectedItemData: sortedItemData[firstIndex], selectedPeriodicity: firstChild, selectedLookBack: sortedItemData[firstIndex].timeback,
            inSearchMode: false,
            selectedLookBack: undefined
        });
        if(!this.state.inSearchMode){
            this.updateDetailScrollTop(newscrollTop);
        }
        scrollTop = newscrollTop;
    }

    updateDropDownPositions() {
        const metricDetailsBlock = this.matricDetailsBlock ? ReactDOM.findDOMNode(this.matricDetailsBlock) : null;
        if (metricDetailsBlock && this.props.dimensions) {
            const intervalDropDown = this.metricIntervals ? ReactDOM.findDOMNode(this.metricIntervals) : null;
            const lookbackDropdown = this.metricIntervalLookback ? ReactDOM.findDOMNode(this.metricIntervalLookback) : null;
            const bottom = this.props.dimensions.bottom - 10;
            const intervalTotalheight = numOfIntervals * 23;
            const lookbackTotalheight = numOfLookbacks * 23;
            if (intervalDropDown && (bottom - intervalDropDown.getBoundingClientRect().bottom <= intervalTotalheight)) isIntervalDropUp = true;
            else isIntervalDropUp = false;
            if (lookbackDropdown && (bottom - lookbackDropdown.getBoundingClientRect().bottom <= lookbackTotalheight)) isLookbackDropUp = true;
            else isLookbackDropUp = false;
            this.forceUpdate();
        }
    }

    updateDropdownScroll() {
        let height, listItemHeight = undefined;
        let lookbackDropdown = document.querySelector(".lookback ul");
        let listItem = document.getElementsByClassName("lookback-menuitem")[0];
        if (listItem) listItemHeight = listItem.clientHeight;

        if (size(this.state.lookbacks) > 12) { height = "290px"; }
        else { height = size(this.state.lookbacks) * listItemHeight + 10 + "px"; }
        if (lookbackDropdown) lookbackDropdown.style.height = height;
    }

    arrangeIntervals(intervalkeys, intervalsFull) {
        // Finding Interval for seacrhed text - return null if text is not shorthand else return Interval
        const intervalForShortHand = this.findIntervalForShortHandText(intervalsFull, this.props.searchText);
         let intervals = intervalkeys;
        each(intervals, (interval, key) => {
            if (intervalForShortHand && interval == intervalForShortHand) {
                let arrangedIntervals = [interval];
                intervals.splice(key, 1);
                each(intervals, (interval) => { arrangedIntervals.push(interval) })
                intervals = arrangedIntervals;
            }
        })
        return intervals[firstIndex];
    }

    findIntervalForShortHandText(object, searchValue) {
        for (const key in object) {
            if (object.hasOwnProperty(key)) {
                const values = object[key];
                for (const value of values) {
                    if (value.itemSymbol.toLowerCase() === searchValue?.toLowerCase()) {
                        return key;
                    }
                }
            }
        }
        return null; // Return null if the search value is not found in the Interval object's itemSymbol
    }

    handleKeyChange(e) {
        if (this.props.inSearchMode == false) {
            let index = undefined;
            if (metricIndex >= 0 && metricIndex < metricDetail.length && !this.props.isColumnFilter) {
                if (e.keyCode == KeyCodes.UP_ARROW && metricIndex == 0) {
                    index = 0;
                } else if (e.keyCode == KeyCodes.DOWN_ARROW && metricIndex == metricDetail.length - 1) {
                    index = metricDetail.length - 1;
                }
                else {
                    index = e.keyCode == KeyCodes.UP_ARROW ? metricIndex - 1 : e.keyCode == KeyCodes.DOWN_ARROW ? metricIndex + 1 : undefined;
                }
            }
            if (index != undefined) {
                metricIndex = index;
                this.handleDetailMetricChange(metricDetail[index], index, e);
            }
        }
        e.preventDefault();
    }

    updateDetailScrollTop(scrollValue) {
        this.props.updateDetailScrollTop(scrollValue);
    }

    getScrollValueForSearch(e, index) {
        let element = undefined;
        let searchbarHeight = document.getElementById("searchfield").getBoundingClientRect().height.toFixed(2);
        if (BrowserUtil.getBrowserName() == "Chrome") {
            element = e.nativeEvent.composedPath()[1];
        } else {
            // let elem = e.target.parentNode; element = elem.parentNode;
            element = e.target.parentNode;
        }
        let scrollstate = scrollTop;
        let scrollValue = 0;
        //let totalHeight = 0;
        // totalHeight = parseInt(searchbarHeight) + parseInt(this.props.detailListHeight) + parseInt(this.props.panelHeadingHeight);
        let visibleItemsCount = this.props.detailListHeight / element.offsetHeight;
        let numOfMetrics = index - parseInt(visibleItemsCount);

        if (numOfMetrics > 0 && scrollstate == 0) {
            scrollValue = Math.ceil(numOfMetrics) * element.offsetHeight;
        } else if (scrollstate > 0) {
            let hiddenMetricsHeight = element.offsetTop - scrollstate;
            let totalHeightOnScroll = hiddenMetricsHeight - parseInt(searchbarHeight) - parseInt(this.props.panelHeadingHeight);
            if (totalHeightOnScroll < this.props.detailListHeight) { 
                scrollValue = totalHeightOnScroll + parseInt(visibleItemsCount) * element.offsetHeight; 
            }
            else {
                scrollValue = totalHeightOnScroll + parseInt(visibleItemsCount) * element.offsetHeight;
            }
        }
        this.updateDetailScrollTop(scrollstate + scrollValue);
        return scrollstate + scrollValue;
    }


    handleIntervalChange(event) {
        let sortedItemData = this.sortItemdataForPeriodicity(this.state.selectedMetric[event]);
        this.setState({
            itemDataForSelectedPeriodicity: sortedItemData,
            selectedItemData: sortedItemData[firstIndex],
            selectedPeriodicity: event,
            selectedLookBack: firstIndex
        });
    }

    handleLookbackChange(event) {
        let itemDataForSelectedPeriodicity = this.state.itemDataForSelectedPeriodicity;
        this.setState({
            selectedItemData: itemDataForSelectedPeriodicity[event[1]],
            selectedLookBack: event[1]
        })
    }

    getIntervalLookback() {
        let self = this;
        let dropdowns = undefined;
        let searchStr = ListStore.getSearchString();
        let selectedMetric =searchStr?ListStore.GetItemDataBySearchString(this.state.selectedMetric): this.state.selectedMetric;

        if (this.state.selectedMetric && this.isIntervalAvailable(selectedMetric)) {
            let requiredIntervals = this.removeCurrentItem(Object.keys(selectedMetric));
            if (this.state.selectedPeriodicity == undefined) this.state = { ...this.state, selectedPeriodicity: requiredIntervals[firstIndex] };

            let selectedPeriodicity = this.state.selectedPeriodicity;
            let intervals = requiredIntervals.map((interval, intervalIndex) => {
                let title = interval;
                switch (interval) {
                    case "Annual": title = LocalizationStore.getTranslatedData("LM_TP_Interval_06", interval); break;
                    case "Quarterly": title = LocalizationStore.getTranslatedData("LM_TP_Interval_05", interval); break;
                    case "Monthly": title = LocalizationStore.getTranslatedData("LM_TP_Interval_04", interval); break;
                    case "Weekly": title = LocalizationStore.getTranslatedData("LM_TP_Interval_03", interval); break;
                    case "Daily": title = LocalizationStore.getTranslatedData("LM_TP_Interval_02", interval); break;
                    case "Semiannual": title = LocalizationStore.getTranslatedData("LM_TP_Interval_08", interval); break;
                    case "Trailing 2 Semiannuals": title = LocalizationStore.getTranslatedData("LM_TP_Interval_09", interval); break;
                    case "Trailing 4 Quarters": title = LocalizationStore.getTranslatedData("LM_TP_Interval_07", interval); break;
                    case undefined: title = requiredIntervals[firstIndex]; break;
                    default: break;
                }
                let tickmark = (selectedPeriodicity == interval) ? <span className="icn-right-icon blank-sp1"></span> : '';
                return (<MenuItem className="xx-small-normal" id="menuitem-dropdown" eventKey={interval} key={intervalIndex + 1}>{tickmark}{title}</MenuItem>)
            });

            if (this.state.itemDataForSelectedPeriodicity == undefined) this.state = { ...this.state, itemDataForSelectedPeriodicity: selectedMetric[this.state.selectedPeriodicity] };
            let requiredLookbacks = this.getRequiredLookBacks(this.state.selectedMetric);
            this.state = { ...this.state, lookbacks: requiredLookbacks };
            numOfLookbacks = requiredLookbacks.length;
            numOfIntervals = requiredIntervals.length;

            if (this.state.selectedLookBack == undefined) {
                this.state = { ...this.state, selectedLookBack: firstIndex };
            }

            let selectedLookback = this.state.selectedLookBack;
            let lookbacks = requiredLookbacks.map((lookback, lookbackIndex) => {
                let tickmark = (selectedLookback == lookbackIndex) ? <span className="icn-right-icon blank-sp1"></span> : '';
                return (<MenuItem className="lookback-menuitem xx-small-normal" eventKey={[lookback, lookbackIndex]} key={lookbackIndex + 1} onSelect={self.handleLookbackChange} onClick={self.toggleLookbackMenuItem}>{tickmark}{lookback}</MenuItem>)
            });

            intervalAttributes['dropup'] = isIntervalDropUp;
            lookbackAttributes['dropup'] = isLookbackDropUp;
            let span = isLookbackDropUp == true ? "dropup-down" : "";
            let lookdownClassName = "lookback-dropdown  btn-secondary xx-small-normal " + span;

            let lookbackDropdownTitle = this.state.lookbacks[this.state.selectedLookBack] ? this.state.lookbacks[this.state.selectedLookBack] : this.state.lookbacks[firstIndex];            
            let selectedPeriodicityTitle = this.state.selectedPeriodicity;
            switch (this.state.selectedPeriodicity) {
                case "Annual": selectedPeriodicityTitle = LocalizationStore.getTranslatedData("LM_TP_Interval_06", this.state.selectedPeriodicity); break;
                case "Quarterly": selectedPeriodicityTitle = LocalizationStore.getTranslatedData("LM_TP_Interval_05", this.state.selectedPeriodicity); break;
                case "Monthly": selectedPeriodicityTitle = LocalizationStore.getTranslatedData("LM_TP_Interval_04", this.state.selectedPeriodicity); break;
                case "Weekly": selectedPeriodicityTitle = LocalizationStore.getTranslatedData("LM_TP_Interval_03", this.state.selectedPeriodicity); break;
                case "Daily": selectedPeriodicityTitle = LocalizationStore.getTranslatedData("LM_TP_Interval_02", this.state.selectedPeriodicity); break;
                case "Semiannual": selectedPeriodicityTitle = LocalizationStore.getTranslatedData("LM_TP_Interval_08", this.state.selectedPeriodicity); break;
                case "Trailing 2 Semiannuals": selectedPeriodicityTitle = LocalizationStore.getTranslatedData("LM_TP_Interval_09", this.state.selectedPeriodicity); break;
                case "Trailing 4 Quarters": selectedPeriodicityTitle = LocalizationStore.getTranslatedData("LM_TP_Interval_07", this.state.selectedPeriodicity); break;
                default: break;
            }
            if ((requiredIntervals.length == 1 && requiredLookbacks.length > 1) || (requiredIntervals.length > 1 && requiredLookbacks.length > 1) || (requiredLookbacks.length == 1 && requiredIntervals.length > 1)) {
                dropdowns = (<div className="inter-look 3">
                        <div className="interval">
                            <h6>{LocalizationStore.getTranslatedData("tp_drpdwn_int", "Interval")}</h6>
                            <DropdownButton {...intervalAttributes} onMouseEnter={this.updateDropDownPositions} bsSize="xsmall" className={`interval-dropdown btn-secondary xx-small-normal ${isIntervalDropUp ? 'dropup-down' : ''}`} ref={(d) => this.metricIntervals = d} id="metric-interval" title={selectedPeriodicityTitle} onSelect={this.handleIntervalChange}>
                                {intervals}
                            </DropdownButton>
                        </div>
                        <div className="lookback">
                            <h6>{LocalizationStore.getTranslatedData("LM_TP_Lookback", "Lookback")}</h6>
                            <DropdownButton {...lookbackAttributes} onMouseEnter={this.updateDropDownPositions} ref={(d) => this.metricIntervalLookback = d} open={this.state.openLookback} bsSize="xsmall" className={lookdownClassName} id="metric-interval-lookback" title={lookbackDropdownTitle} disabled={(this.state.lookbacks.length == 1)} onToggle={this.toggleLookbackDropdownButton}>
                                <div className="custom-scroll-light">
                                    <div id="metric-interval-lookback2">{lookbacks}</div>
                                </div>
                                <ScrollBar scrollId="metric-interval-lookback2" vScroll={true} />
                            </DropdownButton>
                        </div>
                    </div>)
            }
            return dropdowns;
        } else {
            return (<div></div>)
        }
    }

    toggleLookbackDropdownButton() {
        this.setState({ openLookback: !this.state.openLookback });
    }

    toggleLookbackMenuItem() {
        this.setState({ openLookback: false });
    }

    isIntervalAvailable(selectedMetric) {
        //bug fixes - panweb-1216
        let allPeriodicity = keys(this.props.periodicityData);
        let selectedPeriodicity = keys(selectedMetric);
        return intersection(allPeriodicity, selectedPeriodicity).length >= 1 ? true : false;

        // if (selectedMetric.Quarterly || selectedMetric.Annual ||
        //     selectedMetric["Trailing 4 Quarters"] || selectedMetric.Daily ||
        //     selectedMetric.Monthly || selectedMetric.Monthly || selectedMetric.Weekly) {
        //     return true;
        // }
        // return false;
    }

    isColumnExist() {
        for (let i = 0; i < columnsinList.length; i++) {
            if (this.state.selectedItemData && this.state.selectedItemData.MsItemID == columnsinList[i]) return true;
        }
        return false;
    }

    getAddColumnButtonClassName() {
        let className = "add-column-button";
        let customMetricState = ListStore.getCustomMetricState();
        if (!this.props.isColumnFilter && this.isColumnExist() == true && !customMetricState) className = className + " nohover";
        return className;
    }

    getColumnsInList(listState) {
        let columns = [];
        if (listState) {
            each(listState.columns, (columnInfo) => columns.push(columnInfo.dataItemId))
        }
        return columns;
    }

    getStaticAddMetricTitle() {
        let title = LocalizationStore.getTranslatedData("LM_TP_SltMtrAdd", "Click to Add Metric");
        if (this.props.isColumnFilter) {
            title = LocalizationStore.getTranslatedData("LM_TP_SltMtrCompare", "Select a Metric to Compare");
        }
        else if (this.isColumnExist() == true) title = LocalizationStore.getTranslatedData("LM_TP_SltMtrAddMsg1", "Metric is in the current Column Set");
        return title;
    }

    getDescriptionViewState() {
        let style = "block";
        if (this.state.inSearchMode == true) { style = "none"; }
        return style;
    }

    trackUsage() {
        let columnSetInfo;
        let selectedTab = ListStore.getState().SelectedTabKey;
        let tabType = ListStore.getTabType(selectedTab);
        switch (tabType) {
            case ListManagerTabType.Oneil:
                columnSetInfo = ONeilViewStore.getState();
                 propsVal = ['ListView', columnSetInfo.columnSetName, columnSetInfo.columnSetId.toString(), ListExplorerStore.getParentNodeDescription()];
                break;
            case ListManagerTabType.Ownership:
                columnSetInfo = OwnershipViewStore.getState();
                // propsVal = ['ListView', columnSetInfo.columnSetName, columnSetInfo.columnSetId.toString(), getParentNodeDescription()];
                break;
        }
        if (this.state.selectedItemData) {
            let value = this.state.selectedItemData.MetricDisplayName;
            let props = ['ListView', columnSetInfo.columnSetName, columnSetInfo.columnSetId.toString(), ListExplorerStore.getParentNodeDescription()];

            //Log Usage
            ConsoleStore.trackUsage('ListColumnCreated', value, props);
        }
    }

    render() {
        let selectedTab = ListStore.getState().SelectedTabKey;
        let tabType = ListStore.getTabType(selectedTab);
        let resizeClassName = "resize-metric-library-detail";
        let innerPanel = document.getElementsByClassName("inner-panel-body  matric-block-wrap")[0];
        switch (tabType) {
            case ListManagerTabType.Oneil:
                listState = ONeilViewStore.getState();
                break;
            case ListManagerTabType.Ownership:
                listState = OwnershipViewStore.getState();
                break;
        }
        let metricDetails = this.props.dataSource;
        if (isDetailClicked) resizeClassName += " resize-height";

        if (metricDetails != undefined) {
            columnsinList = this.getColumnsInList(listState);
            metricList = this.getMetricsList(metricDetails);
            interval = this.getIntervalLookback(metricDetails);
        }
        resizerHeight = this.props.detailListHeight;
        childMetricsBlockHeight = this.props.detailListHeight;
        if (childMetricsBlockHeight && !childMetricsBlockHeight.toString().includes("%")) {
            childMetricsBlockHeight += "px";
        }

        if (this.state.selectedDetail == undefined) {
            return (
                <div id="matricblockinnerblock" ref={(d) => this.matricDetailsBlock = d} className="matric-block-inner-block">
                    <div id="childMetricsBlock" className="child-metrics-block" style={{ height: "100%" }} >
                        <div className="metric-detail-list" style={{ height: "100%" }}>
                            <div className="child-metrics custom-scroll-light" style={{ height: "100%" }}>
                                <div className="custom-scroll">
                                    <div id='merticLibraryDetailVerticalScroll' onScroll={this.onScroll}>
                                        <ul className="matric-list-group details-matrics matrics-search" ref={(ul) => { this.metricsList = ul }} tabIndex="-2" onKeyDown={this.handleKeyChange}>
                                            {metricList}
                                        </ul>
                                        <ScrollBar scrollId="merticLibraryDetailVerticalScroll" vScroll={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <React.Fragment>
                    <div id="matricblockinnerblock" ref={(d) => this.matricDetailsBlock = d} className="matric-block-inner-block">
                        <ResizableWindow className={resizeClassName} minHeight={this.metricLibrarySettings.MinHeight} maxHeight={this.metricLibrarySettings.MaxHeight} width={innerPanel.clientWidth} height={resizerHeight} direction="Vertical" onStartResize={this.onResize}>
                            <div id="childMetricsBlock" className="child-metrics-block" style={{ height: `${childMetricsBlockHeight}` }} >
                                <div className="metric-detail-list" style={{ height: `${childMetricsBlockHeight}` }}>
                                    <div className="child-metrics custom-scroll-light" style={{ height: `${childMetricsBlockHeight}` }}>
                                        <div className="custom-scroll">
                                            <div id='merticLibraryDetailVerticalScroll' onScroll={this.onScroll}>
                                                <ul className="matric-list-group details-matrics" ref={(ul) => this.metricsList = ul} tabIndex="-2" onKeyDown={this.handleKeyChange}>
                                                    {metricList}
                                                </ul>
                                                <ScrollBar scrollId="merticLibraryDetailVerticalScroll" vScroll={true} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="matric-resize">=</div>
                            <div className="second-block metrics-add-column-section">
                                <h4>{this.getStaticAddMetricTitle()}</h4>
                                <button className={this.getAddColumnButtonClassName()} type="button" onClick={this.handleAddMetric}>{this.state.selectedItemData == undefined ? "" : LocalizationStore.getTranslatedData("DataItemExt_DisplayName_" + this.state.selectedItemData.MsItemID.toString(), this.state.selectedItemData.MetricDisplayName)}</button>
                                {interval}
                                <h4>{LocalizationStore.getTranslatedData("list_des", "Description")}</h4>
                                <div className="metrics-description">
                                    <p className="h30p">{this.state.selectedItemData == undefined ? "" : LocalizationStore.getTranslatedData("DataItemExt_Definition_" + this.state.selectedItemData.MsItemID, this.state.selectedItemData.isCustomColumn?this.state.selectedItemData.Description:this.state.selectedItemData.Definition)}</p>
                                </div>
                            </div>
                        </ResizableWindow>
                    </div>
                    {/*<div className="metrics-add-column" style={{ display: this.getDescriptionViewState() }} >
                        <div className="metric-detail-list">
                                <div className="metrics-add-column-section">
                                <div className="metric-current-column-title medium-bold">{this.getStaticAddMetricTitle()}</div>

                                    {interval}

                                    <div className= "dynamic-description-title-details small-normal">{this.state.selectedItemData.Definition}</div>
                            </div>
                        </div>
                    </div>*/}
                </React.Fragment>);
        }
    }
}